
[data-aspect-ratio] {
  display: block;
  max-width: 100%;
  position: relative;

  &:before {
    content: '';
    display: block;
  }

  > * {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
[data-aspect-ratio]:before {
  padding-top: 62.5%;
}
[data-aspect-ratio="3:1"]:before {
  padding-top: 33.33%;
}
[data-aspect-ratio="2:1"]:before {
  padding-top: 50%;
}
[data-aspect-ratio="16:9"]:before {
  padding-top: 56.25%;
}
[data-aspect-ratio="16:10"]:before {
  padding-top: 62.5%;
}
[data-aspect-ratio="3:2"]:before {
  padding-top: 66.66%;
}
[data-aspect-ratio="4:3"]:before {
  padding-top: 75%;
}
[data-aspect-ratio="1:1"]:before {
  padding-top: 100%;
}
[data-aspect-ratio="3:4"]:before {
  padding-top: 133.33%;
}
[data-aspect-ratio="2:3"]:before {
  padding-top: 150%;
}
[data-aspect-ratio="9:16"]:before {
  padding-top: 177.77%;
}
[data-aspect-ratio="1:2"]:before {
  padding-top: 200%;
}
[data-aspect-ratio="1:3"]:before {
  padding-top: 300%;
}





.navbar-header {
  padding:15px;
  width:100%;
}
.form-signin-heading {
  text-align: center;
}

.nav>li>a:focus,
.nav>li>a:hover,
.nav>li.active>a {
  //background-color: #6c343b !important;
}

@media (min-width: 768px){
  .navbar-nav>li>a {
    margin: 5px;
  }
}



a.btn, .btn {
  background-color: #6F777D !important;
  border:0px;

  padding-top: 8px !important;
  padding-bottom:8px !important;

  &.btn-primary {
    background-color: #6F777D !important;
  }
  &:hover.btn-primary {
    background-color: #8E454E !important;
  }

}

a.btn-danger, .btn-danger {
  background-color: #d9534f !important;
  border-color: #d43f3a !important;
}



.tablinks {

  li {
    a {
      background-color:  #6F777D;
      color:#FFF;
      border-color:#DDD;
    }

    &.active a,
    &.active a:focus {
      background-color: #8E454E;
      color:#FFF;

    }


  }

}



.blockborder {
  &>div {
    border-right: 1px solid #ddd;
    &:last-child {
      border-right:0;
    }
  }



}


.imagelisting {
  margin-top:20px;
  margin-bottom:20px;
}

.imgborder {

  width:100%;
  border:2px solid black;
  padding-bottom:20px;
  text-align:center;
  position: relative;

  img {
    max-width:100%;
    max-height:100%;
    margin:0px auto;
  }

  &>.imgdesc {
    height:20px;

    position: absolute;
    bottom:0px;
    left:0px;
    width:100%;
    background:#000;
    color:#FFF;
    font-size:10px;
  }


}


.pagination {
  margin: 0px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border:none;
  background:none;
  padding-radius: 0px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  border:0px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding:0px;
  border:0px;
}

.imageframe {
  border:2px solid black;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding:10px;
  position: relative;
  .delbtn {
    position: absolute;
    bottom:-10px;
    right:-10px;
    border-radius: 100%;
    background-color: white;
    padding: 5px;

  }
}

.charttypeselector .single,
.charttypeselector .multiple {
  display:none;
}

.documentfiles > .row {
  margin-top: 10px;
  margin-bottom: 10px;
}

.single > .single {
  display:table-row;
}

.multiple > .multiple {
  display:table-row;
}

.removeMachinery {
  display:none;
}

#machinerytarget .addMachinery {
    display:none;
}
#machinerytarget .removeMachinery {
    display:block;
}


.sortup,
.sortdown {
  display:none;
}

tr:first-child .sortup {
  display:none !important;
}
tr:last-child .sortdown {
  display:none !important;
}

.sortingallowed .sortup,
.sortingallowed .sortdown {
  display:inline-block;
  margin-top: 10px;
}






/* Soertables */

#sortable1, #sortable2{
  margin: 0 0 30px 0;
  min-height: 20px;
  border:1px solid lightgrey;
  .radiobuttons {
    display:none;
  }
}
#sortable2 .radiobuttons {
  display:inline-block;
  float:right;
}

#sortable1 li, #sortable2 li {
  padding: 5px;
  font-size: 1.2em;
  width: 100%;
  border: 1px solid black;
  background: #FFF;
}



#trash {
  height: 21px;
  width: 100%;
  border: 1px solid red;
}
#trash.ui-state-hover{
  background: red;
}